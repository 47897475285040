.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a {
  text-decoration: none !important;
  color: inherit !important;
  
}

.container{
  font-family: 'Play', sans-serif;
}

.h2{
  font-family: 'Play', sans-serif;
}

.b{
  font-family: 'Play', sans-serif;
}

.back{
  background-color: rgb(206, 21, 123);
  padding: 8px;
}

.back h3{
  font-family: 'Ubuntu', sans-serif;
}

.text h2{
  font-family: 'Ubuntu', sans-serif;
}
.text h3{
  font-family: 'Ubuntu', sans-serif;
  color: rgb(48, 38, 105);
}

.welcometo{
  color: rgb(145, 9, 115)
}